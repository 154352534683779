import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import Loading from '../Loading'

export default class Frames extends Component {
  render() {
    const {
      loading,
      frames,
      users,
      messageCounts,
      countsBeingLoaded,
      getMessageCounts,
      selectedFrameIds,
      toggleFrame,
      resetFrame,
      refreshFrame,
      canReset,
      migrateModal,
      transferModal,
      codeModal,
      searchQuery,
      showSearchEmptyState,
    } = this.props
    return (
      <div>
        {loading && <Loading />}
        {!loading && (!frames || frames?.length === 0) && (
          <div style={{ height: 200 }} className="d-flex align-items-center justify-content-center">
            <h2>
              {searchQuery && !showSearchEmptyState
                ? 'No frames found. Please adjust search and try again.'
                : 'Search to see frames here!'}
            </h2>
          </div>
        )}
        {!loading &&
          frames?.length > 0 &&
          frames.map((frame) => {
            const counts = messageCounts[frame.id]
            const loadingCount = countsBeingLoaded[frame.id]
            const user = users.find((u) => u.id === frame.relationships.user.data.id)
            const frameOrTv =
              frame.attributes.apps.indexOf('photos') !== -1 ||
              frame.attributes.apps.indexOf('tv') !== -1
            return (
              <div key={frame.id} className="row mt-3 align-items-center border-bottom">
                <div className="col-sm-5">
                  <div className="form-group mb-0">
                    <label className="form-check-label">
                      {toggleFrame && (
                        <input
                          label={`Toggle ${frame.attributes.name}`}
                          className="form-check-input"
                          checked={selectedFrameIds.indexOf(frame.id) !== -1}
                          type="checkbox"
                          onChange={() => toggleFrame(frame.id)}
                        />
                      )}
                      <h4>
                        <Link to={`/admin-dash/frames/${frame.id}/request-approval`}>
                          {frame.attributes.name}
                        </Link>{' '}
                        {frame.attributes.application_version_name && (
                          <small className="text-muted">
                            v{frame.attributes.application_version_name}
                          </small>
                        )}
                        {frame.attributes.hardware_model && (
                          <span className="text-muted text-sm">
                            &nbsp;{frame.attributes.hardware_model}
                          </span>
                        )}
                        <span className="text-muted text-sm">
                          &nbsp;
                          {frame.attributes.apps.indexOf('photos') !== -1 && (
                            <span
                              className="oi oi-image frame-app-icon"
                              title="Photo Frame"
                              aria-hidden="true"
                            />
                          )}
                          {frame.attributes.apps.indexOf('calendar') !== -1 && (
                            <span
                              className="oi oi-calendar frame-app-icon"
                              title="Calendar"
                              aria-hidden="true"
                            />
                          )}
                          {frame.attributes.apps.indexOf('tv') !== -1 && (
                            <span
                              className="oi oi-monitor frame-app-icon"
                              title="TV"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                        <span className="text-muted text-sm">
                          &nbsp;{frame.attributes.open_to_public ? ' Open to Public' : ' Private'}
                          &nbsp;
                          {frame.attributes.activated && `| ${frame.attributes.manufacturer}`}
                        </span>
                        {frame.attributes.watchdog_version_name && (
                          <span className="text-muted text-sm">
                            &nbsp;| Watchdog v{frame.attributes.watchdog_version_name}
                          </span>
                        )}
                        {frame.attributes.serial_number && (
                          <span className="text-muted text-sm">
                            &nbsp;| s/n {frame.attributes.serial_number}
                          </span>
                        )}
                      </h4>
                    </label>
                  </div>
                  {user && (
                    <p>
                      &nbsp;
                      <Link to={`/admin-dash/users/${user.id}`}>{user.attributes.email}</Link>{' '}
                      {frame.attributes.calendar_sync && <span className="sync-label">sync</span>}{' '}
                      {frame.attributes.plus && <span className="plus-label">plus</span>}
                      {frame.attributes.trialing && <span className="trial-label">trial</span>}
                    </p>
                  )}
                </div>
                {frame.attributes.destroyed_at ? (
                  <div className="col-sm-7">
                    <h2 className="text-right text-muted">Deleted.</h2>
                  </div>
                ) : (
                  <div className="col-sm-7 text-right">
                    <div className="btn-group" role="group" aria-label="Frame Actions">
                      {!frame.attributes.activated && (
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() => codeModal(frame)}
                        >
                          Get Activation Code
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-info btn-sm"
                        onClick={() => transferModal(frame)}
                      >
                        Transfer
                      </button>
                      {canReset && (
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={() => resetFrame(frame)}
                        >
                          Reset
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-rose btn-sm"
                        onClick={() => refreshFrame(frame)}
                      >
                        Refresh
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning btn-sm"
                        onClick={() => migrateModal(frame)}
                      >
                        Migrate
                      </button>
                      {frameOrTv && (
                        <Link
                          className="btn btn-secondary btn-sm"
                          to={`/frames/${frame.id}/privacy`}
                        >
                          Privacy
                        </Link>
                      )}
                    </div>
                    {counts ? (
                      <p>
                        <b>{counts.num_messages} </b>
                        Photo{counts.num_messages === 1 ? '' : 's'} in Total <br />
                        <b>{counts.num_messages_processing} </b>
                        Processing <br />
                        <b>{counts.num_messages_pending_nsfw_approval} </b>
                        Pending NSFW Approval <br />
                        <b>{counts.num_messages_pending_spam_approval} </b>
                        Pending Spam Approval <br />
                        <b>{counts.num_messages_pending_frame_user_approval} </b>
                        Pending Sender Approval <br />
                        <b>{counts.num_messages_awaiting_download} </b>
                        Awaiting Download <br />
                        <b>{counts.num_messages_downloaded} </b>
                        Downloaded <br />
                        <b>{counts.num_messages_invalid_asset_type} </b>
                        Invalid File Type <br />
                        <b>{counts.num_messages_rejected} </b>
                        Blocked by Owner <br />
                        <b>{counts.num_messages_month_in_review} </b>
                        Month in Review
                      </p>
                    ) : (
                      <p>
                        <small>
                          {loadingCount ? (
                            <span>Loading counts...</span>
                          ) : (
                            <span>
                              <a href="#" onClick={(e) => getMessageCounts(e, frame.id)}>
                                Get message counts
                              </a>
                              {frame.attributes.apps.indexOf('calendar') !== -1 && (
                                <span>
                                  {' '}
                                  |{' '}
                                  <Link to={`/frames/${frame.id}/messages?tab=photos`}>
                                    See slideshow photos
                                  </Link>
                                </span>
                              )}
                            </span>
                          )}
                        </small>
                      </p>
                    )}
                  </div>
                )}
              </div>
            )
          })}
      </div>
    )
  }
}

Frames.propTypes = {
  loading: PropTypes.bool.isRequired,
  frames: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  resetFrame: PropTypes.func.isRequired,
  refreshFrame: PropTypes.func.isRequired,
  migrateModal: PropTypes.func.isRequired,
  transferModal: PropTypes.func.isRequired,
  codeModal: PropTypes.func.isRequired,
  messageCounts: PropTypes.object.isRequired,
  countsBeingLoaded: PropTypes.object.isRequired,
  getMessageCounts: PropTypes.func.isRequired,
  canReset: PropTypes.bool.isRequired,
  toggleFrame: PropTypes.func,
  selectedFrameIds: PropTypes.array,
  searchQuery: PropTypes.string,
  showSearchEmptyState: PropTypes.bool,
}
